import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Item from "../components/video/Item";

const Videos = ({ data: { allSanityVideos } }) => {
  return (
    <Layout footer={false}>
      <div
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(69,204,55,0.6), rgba(41,217,211,0.6)`,
        }}
        className="h-full md:h-[100vh]"
      >
        <div className="max-w-[1366px] w-full mx-auto pt-32 pb-16 show">
          <div className=" w-[90%] mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-6 gap-y-[47px] justify-center items-center">
            {allSanityVideos?.edges?.map((item, index) => (
              <Item
                item={item.node}
                key={index}
                list={allSanityVideos?.edges}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Videos;

export const query = graphql`
  query {
    allSanityVideos(sort: { order: DESC, fields: _createdAt }) {
      edges {
        node {
          length
          title
          slug {
            current
          }
          thumbnail {
            alt
            asset {
              gatsbyImageData(
                fit: FILLMAX
                placeholder: BLURRED
                height: 250
                width: 400
                formats: WEBP
              )
            }
          }
        }
      }
    }
  }
`;
